import * as React from 'react';

function TermInput({onClick, term}) {
    const onMyClick = e => {
        onClick(term);
    }

    return <button onClick={onMyClick}>{term}</button>
}

export default TermInput;
