import * as React from 'react';
import TermInput from './TermInput';

function Keyboard({onTermClick, onEnterClick, onDeleteClick}) {
    const xterms = ["x"];
    for (let i = 2; i < 10; ++i) {
        xterms.push(i + "x")
    }

    const xrow = xterms.map(term => <TermInput key={term} onClick={onTermClick} term={term} />)

    const numterms = [];
    for (let i = 1; i < 10; ++i) {
        numterms.push(i);
    }

    const numrow = numterms.map(term => <TermInput key={term} onClick={onTermClick} term={term} />)

    const oprow = [];
    oprow.push(<TermInput key="Delete" onClick={onDeleteClick} term="Delete" />)
    oprow.push(<TermInput key="-" onClick={onTermClick} term="-" />)
    oprow.push(<TermInput key="+" onClick={onTermClick} term="+" />)
    oprow.push(<TermInput key="Enter" onClick={onEnterClick} term="Enter" />)

    return (
        <>
            <div className="buttonrow">{xrow}</div>
            <div className="buttonrow">{numrow}</div>
            <div className="buttonrow">{oprow}</div>
        </>
    );
}

export default Keyboard;