import Puzzle from "./Puzzle";

function genPosTerm() {
    return Math.floor(Math.random() * 9) + 1;
}

function genPosNotOneTerm() {
    return Math.floor(Math.random() * 8) + 2;
}

function genTerm() {
    let ret = genPosTerm();
    if (Math.random() < 0.5) {
        return -ret;
    }
    return ret;
}

const primes = [2, 3, 5, 7];

function genPrime() {
    let index = Math.floor(Math.random() * primes.length);
    return primes[index];
}

function gcd(a, b) {
    if (a === b) {
        return a;
    }

    if (a === 0 || b === 0) {
        return Math.max(a, b);
    }

    if (a === 1 || b === 1) {
        return 1;
    }

    const min = Math.min(a, b);
    const max = Math.max(a, b);

    return gcd(max % min, min);
}

function genTermNoGcd(a) {
    let b;
    do {
        b = genTerm();
    }
    while (gcd(a, Math.abs(b)) !== 1);

    return b;
}

export default class PuzzleFactory {
    constructor() {
        this.leading = "one";
    }

    createPuzzle() {
        let a = 1;
        let c = 1;

        switch(this.leading) {
            case "one":
                a = 1;
                break;
            case "prime":
                a = genPrime();
                break;

            case "notprime":
                a = genPosNotOneTerm();
                if (primes.includes(a)) {
                    c = genPosNotOneTerm();
                }
                else {
                    c = genPosTerm();
                }
                break;
            default:
                throw new Error('invalid puzzle type: ' + this.leading);
        }

        return new Puzzle(a, genTermNoGcd(a), c, genTermNoGcd(c));
    }
}