import * as React from 'react';
import GuessBox from './GuessBox';

function GuessRow({guesses = [], states = []}) {
    const guessboxes = [];
    for (let i = 0; i < 6; ++i) {
        guessboxes.push(<GuessBox key={i} guessState={states[i]} value={guesses[i]} />)
    }

    return <div className="guessrow">
        <div className="guess paren">(</div>
        {guessboxes.slice(0, 3)}
        <div className="guess paren">)</div>
        <div className="guess paren">(</div>
        {guessboxes.slice(3)}
        <div className="guess paren">)</div>
    </div>
}

export default GuessRow;