import './App.css';
import {useState} from 'react';
import Game from './components/Game.js';
import SideMenu from './components/SideMenu';
import PuzzleFactory from './PuzzleFactory';

function App() {
  const [isMenuActive, setMenuActive] = useState(false);
  const [factory] = useState(new PuzzleFactory());
  const [puzzle, setPuzzle] = useState(factory.createPuzzle());

  const onLeadingChanged = (e) => {
    factory.leading = e.target.value;
  }

  return (
    <div className="App">
      <div>
        <button onClick={() => setMenuActive(!isMenuActive)}>Options</button>
        <button onClick={() => setPuzzle(factory.createPuzzle())}>New</button>
      </div>
      <SideMenu isActive={isMenuActive} onLeave={() => setMenuActive(false)}>
        <div className='options' onChange={onLeadingChanged}>
          <label><input type="radio" value="one" name="leadingCoeff" defaultChecked={factory.leading === "one"} />Leading coefficient is 1</label><br />
          <label><input type="radio" value="prime" name="leadingCoeff" defaultChecked={factory.leading === "prime"} />Leading coefficient is not 1 but is prime</label><br />
          <label><input type="radio" value="notprime" name="leadingCoeff" defaultChecked={factory.leading === "notprime"} />Leading coefficient is not 1 or prime</label><br />
        </div>
      </SideMenu>
      <Game puzzle={puzzle} />
    </div>
  );
}

export default App;
