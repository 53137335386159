import * as React from 'react';

function SideMenu({children, isActive, onLeave}) {
    const menuClass = "sidemenu " + (isActive ? "sidemenu-active" : "")
    const contentClass = "sidemenu_content " + (isActive ? "sidemenu_content-active" : "")

    return (
        <aside className={menuClass}>
            <div className='sidemenu_overlay' onClick={onLeave} />
            <div className={contentClass}>
                {children}
            </div>
        </aside>
    )
}

export default SideMenu;