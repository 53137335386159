import * as React from 'react';
import {useState} from 'react';
import GuessRow from './GuessRow';
import Keyboard from './Keyboard';

function Game({puzzle}) {
    const [currPuzzle, setPuzzle] = useState(puzzle);
    const [guesses, setGuesses] = useState([[]]);
    const [endState, setEndState] = useState();
    const [states, setStates] = useState([]);

    if (puzzle !== currPuzzle) {
        //reset the game
        setEndState(null);
        setGuesses([[]]);
        setStates([]);
        setPuzzle(puzzle);
    }

    const guessRows = [];
    for (let i = 0; i < puzzle.numterms; ++i) {
        guessRows.push(<GuessRow key={i} guesses={guesses[i]} states={states[i]} />);
    }

    const onDeleteClick = () => {
        if (endState) {
            return;
        }

        const row = guesses.length - 1;
        const guessRow = guesses[row];
        if (guessRow.length > 0) {
            const newGuesses = [...guesses.slice(0, row), guessRow.slice(0, guessRow.length - 1)];
            setGuesses(newGuesses);
        }
    }

    const onTermClick = (term) => {
        if (endState) {
            return;
        }
        const row = guesses.length - 1;
        const guessRow = guesses[row];
        if (guessRow.length < puzzle.numterms) {
            const newRow = [...guessRow, term];
            const newGuesses = [...guesses.slice(0, row), newRow];
            setGuesses(newGuesses);
        }
    }

    const onEnterClick = () => {
        const row = guesses.length - 1;
        const guessRow = guesses[row];
        if (guessRow.length === puzzle.numterms) {
            const newGuesses = [...guesses, []];

            const results = puzzle.calcGuesses(guessRow);
            const newStates = [...states, results];

            let complete = true;
            for (let res of results) {
                if (res !== "right") {
                    complete = false;
                    break;
                }
            }

            if (complete) {
                setEndState("victory");
            }
            else if (row === 5) {
                setEndState("defeat");
            }

            setGuesses(newGuesses);
            setStates(newStates);
        }
    }

    return <div className={endState}>
        <h1>{puzzle.toFactor}</h1>
        {guessRows}
        <Keyboard onDeleteClick={onDeleteClick} onTermClick={onTermClick} onEnterClick={onEnterClick} />
    </div>

}

export default Game;
